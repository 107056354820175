import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h4>{`Logic chip design: AI ASICs`}</h4>
    <p>{`Application-specific integrated circuits for artificial intelligence ("AI ASICs"), a rapidly growing category of logic chips, often achieve greater speed and efficiency for artificial intelligence than GPUs and FPGAs, but are usable only for specific algorithms. ASICs can be easier to design than CPUs, GPUs, and FPGAs, opening the field to a wide range of design startups, including in China. Still, few highly specialized ASICs have been widely commercialized, as their markets are often too small for recouping fixed development costs.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      